<template>
    <div class="vcard__new__info">
        <div class="vcard__new__info__title">
            <h1>Ingresa los datos</h1>
        </div>
        <div class="vcard__new__info__columns">
            <div class="vcard__new__info__column">
                <div class="vcard__new__info__column__field">
                    <label for="name" class="vcard__new__info__column__field__label">Nombre:
                    </label>
                    <input v-model="employeeInfo.name" type="text" name="name" placeholder="Nombre"
                        class="vcard__new__info__column__field__input" />
                </div>
                <div class="vcard__new__info__column__field">
                    <label for="last" class="vcard__new__info__column__field__label">Apellido:
                    </label>
                    <input v-model="employeeInfo.last" type="text" name="last" placeholder="Apellido"
                        class="vcard__new__info__column__field__input" />
                </div>
                <div class="vcard__new__info__column__field">
                    <label for="job" class="vcard__new__info__column__field__label">Puesto de trabajo:
                    </label>
                    <input v-model="employeeInfo.job" type="text" name="job" placeholder="Puesto de trabajo"
                        class="vcard__new__info__column__field__input" />
                </div>
                <div class="vcard__new__info__column__field">
                    <label for="phone" class="vcard__new__info__column__field__label">Teléfono empleado:
                    </label>
                    <input v-model="employeeInfo.phone" type="number" name="phone" placeholder="Teléfono"
                        class="vcard__new__info__column__field__input" />
                </div>
                <div class="vcard__new__info__column__field">
                    <label for="Sucursal" class="vcard__new__info__column__field__label">Ubicación de sucursal:
                    </label>
                    <select class="vcard__new__info__column__field__input" v-model="employeeInfo.sucursalAddress">
                        <option v-for="branch in getBranches" :key="branch.id" :value="branch.address"> {{ branch.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="vcard__new__info__column">
                <div class="vcard__new__info__column__field">
                    <label for="Correo electronico" class="vcard__new__info__column__field__label">Correo electronico:
                    </label>
                    <input v-model="employeeInfo.mail" type="text" name="Correo electronico"
                        placeholder="Correo electronico:" class="vcard__new__info__column__field__input" />
                </div>
                <div class="vcard__new__info__column__field">
                    <label for="Sucursal" class="vcard__new__info__column__field__label">Sucursal:
                    </label>
                    <select class="vcard__new__info__column__field__input" v-model="employeeInfo.sucursal">
                        <option disabled>Sucursal</option>
                        <option value="dm-sur">Di-medical del sur</option>
                        <option value="dm-corp">Di-medical corporativo</option>
                    </select>
                </div>

                <div class="vcard__new__info__column__field">
                    <label for="Sucursal" class="vcard__new__info__column__field__label">Teléfono local de sucursal:
                    </label>
                    <select class="vcard__new__info__column__field__input" v-model="employeeInfo.sucursalPhone">
                        <option v-for="branch in getBranches" :key="branch.id" :value="branch.phone"> {{ branch.name }} -
                            {{ branch.phone }}</option>
                    </select>
                </div>

                <div class="vcard__new__info__column__field">
                    <label for="name" class="vcard__new__info__column__field__label">Descripción parrafo uno:</label>
                    <textarea v-model="employeeInfo.description1" name="Description"
                        class="vcard__new__info__column__field__input" id="desc" cols="30" rows="10"
                        placeholder="Descripción del empleado">
                              </textarea>
                </div>
                <div class="vcard__new__info__column__field">
                    <label for="name" class="vcard__new__info__column__field__label">Descripción parrafo dos:
                    </label>
                    <textarea v-model="employeeInfo.description2" name="Description"
                        class="vcard__new__info__column__field__input" id="desc" cols="30" rows="10"
                        placeholder="Descripción del empleado">
                              </textarea>
                </div>
            </div>
        </div>
        <div class="vcard__new__info__next" v-if="showButton">
            <button class="vcard__new__info__next__button" @click="nextSection">Siguiente</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "employeeInfo",
    props: {
        infoToUpdate: {
            type: Object,
            default: () => { }
        }
    },

    mounted() {
        if (this.infoToUpdate) {
            this.employeeInfo = this.infoToUpdate

            if (!this.employeeInfo.sucursalPhone) {
                this.employeeInfo.sucursalPhone = ''
            }

            if (!this.employeeInfo.sucursalAddress) {
                this.employeeInfo.sucursalAddress = ''
            }
        }
    },

    data() {
        return {
            employeeInfo: {
                name: "",
                job: "",
                phone: "",
                mail: "",
                last: "",
                sucursal: "",
                description1: "",
                description2: "",
                sucursalPhone: "",
                sucursalAddress: ""
            },
        };
    },

    methods: {
        nextSection() {
            this.$emit("employeeInfo", this.employeeInfo);
            window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        },
    },

    computed: {
        showButton() {
            const {
                name,
                job,
                phone,
                mail,
                last,
                sucursal,
                description1,
                description2,
                sucursalPhone,
                sucursalAddress
            } = this.employeeInfo;
            if (
                !name ||
                !job ||
                !phone ||
                !mail ||
                !sucursal ||
                !description1 ||
                !description2 ||
                !last ||
                !sucursalAddress ||
                !sucursalPhone
            ) {
                return false;
            }
            return true;
        },

        ...mapGetters('vCard', ['getBranches'])
    },
};
</script>


<style>
</style>
